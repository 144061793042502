<template>
  <b-modal
    id="modal-create-profile"
    :title="$t('fee.create.title')"
    title-class="text-airline font-medium-4 font-weight-bolder"
    body-class="p-1"
    size="xl"
    centered
    no-close-on-backdrop
    hide-footer
    @show="showHandle"
  >
    <ValidationObserver ref="refFormObserver">
      <!-- #default="{ }" -->
      <IAmOverlay :loading="(!!loading && loadingCreate) || !employeeProfileToAdd">
        <BRow class="d-flex-center">
          <!-- ANCHOR name -->
          <BCol
            cols="12"
            lg="8"
          >
            <ValidationProvider
              #default="validationContext"
              :name="$t('fee.profileName')"
              rules="required"
            >
              <b-form-group label-for="name-profile">
                <template #label>
                  <span class="font-weight-bolder">{{ $t('fee.profileName') }}</span>
                  <span class="text-danger ml-25">(*)</span>
                </template>
                <b-form-input
                  id="name-profile"
                  v-model.trim="employeeProfileToAdd.name"
                  :state="getValidationState(validationContext) === false ? false : null"
                  :placeholder="$t('fee.placeholder.profileName')"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </BCol>

          <!-- ANCHOR isDefault -->
          <BCol
            cols="12"
            lg="4"
            class="d-flex-center"
          >
            <BFormGroup
              v-if="employeeProfileToAdd"
              label-for="isDefault"
              class="d-flex-center mb-0"
            >
              <BFormCheckbox
                id="isDefault"
                v-model="employeeProfileToAdd.isDefault"
                switch
              >
                <div class="text-nowrap mb-0">
                  <span class="font-weight-bolder">{{ $t('agency.bankAccountsList.isDefault') }}</span>
                </div>
              </BFormCheckbox>
            </BFormGroup>
          </BCol>
        </BRow>

        <div v-if="serviceFeeConfigs && serviceFeeConfigs.length">
          <!-- ANCHOR Bảng nội địa -->
          <h3>{{ $t('fee.domesticFee') }}</h3>
          <b-table
            :fields="tableColumnsForCreate"
            striped
            bordered
            hover
            responsive
            :empty-text="$t('noMatchingResult')"
            :items="serviceFeeConfigs.filter(item => item.ticketType === 'INLAND')"
          >
            <template
              v-for="column in tableColumnsForCreate"
              #[`head(${column.key})`]="data"
            >
              <div
                :key="column.label"
                class="text-dark text-nowrap text-center"
              >
                {{ data.label ? $t(`fee.columns.${data.label}`) : '' }}
              </div>
            </template>

            <template #cell(airline)="data">
              <div class="d-flex-center gap-1 font-weight-bolder">
                <div
                  style="width: 50px"
                  class="d-flex-center"
                >
                  <IAmLogoAirline
                    :airline="data.item.airline.toUpperCase() "
                    :height="30"
                  />
                </div>
                <span class="flex-1">
                  {{ $te(`flight.airlines.${data.item.airline.toUpperCase()}`)
                    ? $t(`flight.airlines.${data.item.airline.toUpperCase()}`)
                    : '' }}
                  ({{ $te(`flight.sourceName.${data.item.airline.toUpperCase()}`)
                    ? $t(`flight.sourceName.${data.item.airline.toUpperCase()}`)
                    : data.item.airline.toUpperCase() }})
                </span>
              </div>
            </template>
            <template #cell(feeType)="data">
              <v-select
                v-model="data.item.feeType"
                :options="data.item.ticketType === 'INLAND' ? feeTypeOptions.filter(item => item.value === 'FLIGHT_ITINERARY') : feeTypeOptions"
                :reduce="(val) => val.value"
                :append-to-body="true"
                :calculate-position="withPopper"
                :disabled="['TRAIN'].includes(data.item.airline)"
                :clearable="false"
                :placeholder="$t('fee.placeholder.feeType')"
              >
                <template #option="{ value }">
                  <span class="d-one-line">
                    {{ $t(`fee.${value}`) }}
                  </span>
                </template>

                <template #selected-option="{ value }">
                  <span class="d-one-line">
                    {{ $t(`fee.${value}`) }}
                  </span>
                </template>

                <template #no-options>
                  {{ $t('noOptions') }}
                </template>
              </v-select>
            </template>
            <template #cell(adultAmount)="data">
              <IAmInputMoney
                id="amount-adult"
                :value-money.sync="data.item.adultAmount"
                class="flex-grow-1"
                :placeholder="$t('fee.placeholder.amount')"
                @input="val => handleInputAdultAmount(val, data.item)"
              />
            </template>
            <template #cell(childAmount)="data">
              <IAmInputMoney
                id="amount-child"
                :value-money.sync="data.item.childAmount"
                class="flex-grow-1"
                :placeholder="$t('fee.placeholder.amount')"
              />
            </template>
            <template #cell(infantAmount)="data">
              <IAmInputMoney
                id="amount-infant"
                :value-money.sync="data.item.infantAmount"
                :disabled="['TRAIN'].includes(data.item.airline)"
                class="flex-grow-1"
                :placeholder="$t('fee.placeholder.amount')"
              />
            </template>
            <template #cell(applyAll)="data">
              <b-button
                :id="`apply-all-${data.item.airline}-${data.item.feeType}-1`"
                variant="flat-info"
                style="padding: 4px"
                @click="handleApplyAll(data.item)"
              >
                <feather-icon
                  class="cursor-pointer"
                  icon="CopyIcon"
                  size="20"
                />
              </b-button>
              <b-tooltip
                :target="`apply-all-${data.item.airline}-${data.item.feeType}-1`"
                triggers="hover focus"
                boundary="viewport"
                variant="info"
              >
                <div>{{ $t('fee.applyAll') }}</div>
                <div>{{ $t('fee.domesticFee') }}</div>
              </b-tooltip>
            </template>
          </b-table>

          <!-- ANCHOR Bảng quốc tế -->
          <h3>{{ $t('fee.internationalFee') }}</h3>
          <b-table
            :fields="tableColumnsForCreate"
            striped
            bordered
            hover
            responsive
            :empty-text="$t('noMatchingResult')"
            :items="serviceFeeConfigs.filter(item => item.ticketType === 'INTERNATIONAL')"
          >
            <template
              v-for="column in tableColumnsForCreate"
              #[`head(${column.key})`]="data"
            >
              <div
                :key="column.label"
                class="text-dark text-nowrap text-center"
              >
                {{ data.label ? $t(`fee.columns.${data.label}`) : '' }}
              </div>
            </template>
            <template #cell(airline)="data">
              <div class="d-flex-center gap-1 font-weight-bolder">
                <div
                  style="width: 50px"
                  class="d-flex-center"
                >
                  <IAmLogoAirline
                    :airline="data.item.airline.toUpperCase() "
                    :height="30"
                  />
                </div>
                <span class="flex-1">
                  {{ $te(`flight.airlines.${data.item.airline.toUpperCase()}`)
                    ? $t(`flight.airlines.${data.item.airline.toUpperCase()}`)
                    : '' }}
                  ({{ $te(`flight.sourceName.${data.item.airline.toUpperCase()}`)
                    ? $t(`flight.sourceName.${data.item.airline.toUpperCase()}`)
                    : data.item.airline.toUpperCase() }})
                </span>
              </div>
            </template>
            <template #cell(feeType)="data">
              <v-select
                v-model="data.item.feeType"
                :options="data.item.ticketType === 'INLAND' ? feeTypeOptions.filter(item => item.value === 'FLIGHT_ITINERARY') : feeTypeOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                :append-to-body="true"
                :calculate-position="withPopper"
                :placeholder="$t('fee.placeholder.feeType')"
              >
                <template #option="{ value }">
                  <span class="d-one-line">
                    {{ $t(`fee.${value}`) }}
                  </span>
                </template>

                <template #selected-option="{ value }">
                  <span class="d-one-line">
                    {{ $t(`fee.${value}`) }}
                  </span>
                </template>

                <template #no-options>
                  {{ $t('noOptions') }}
                </template>
              </v-select>
            </template>
            <template #cell(adultAmount)="data">
              <IAmInputMoney
                id="amount-adult"
                :value-money.sync="data.item.adultAmount"
                class="flex-grow-1"
                :placeholder="$t('fee.placeholder.amount')"
                @input="val => handleInputAdultAmount(val, data.item)"
              />
            </template>
            <template #cell(childAmount)="data">
              <IAmInputMoney
                id="amount-child"
                :value-money.sync="data.item.childAmount"
                class="flex-grow-1"
                :placeholder="$t('fee.placeholder.amount')"
              />
            </template>
            <template #cell(infantAmount)="data">
              <IAmInputMoney
                id="amount-infant"
                :value-money.sync="data.item.infantAmount"
                class="flex-grow-1"
                :placeholder="$t('fee.placeholder.amount')"
              />
            </template>
            <template #cell(applyAll)="data">
              <b-button
                :id="`apply-all-${data.item.airline}-${data.item.feeType}-2`"
                variant="flat-info"
                style="padding: 4px"
                @click="handleApplyAll(data.item)"
              >
                <feather-icon
                  class="cursor-pointer"
                  icon="CopyIcon"
                  size="20"
                />
              </b-button>
              <b-tooltip
                :target="`apply-all-${data.item.airline}-${data.item.feeType}-2`"
                triggers="hover focus"
                boundary="viewport"
                variant="info"
              >
                <div>{{ $t('fee.applyAll') }}</div>
                <div>{{ $t('fee.internationalFee') }}</div>
              </b-tooltip>
            </template>
          </b-table>
        </div>

        <div class="d-flex-center">
          <BButton
            variant="info"
            @click="createEmployeeProfileHandle"
          >
            {{ $t('addNew') }}
          </BButton>
        </div>
      </IAmOverlay>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import {
  BModal, BTable, BFormInput, BRow, BCol, BFormGroup, BFormCheckbox, BButton, BFormInvalidFeedback, BTooltip, VBTooltip,
} from 'bootstrap-vue'
import { computed, ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import cloneDeep from 'lodash/cloneDeep'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { createPopper } from '@popperjs/core'
import unionWith from 'lodash/unionWith'

import { ticketTypeOptions, feeTypeOptions } from '@/constants/selectOptions'
import store from '@/store'
import env from '@/libs/env'

import formValidation from '@core/comp-functions/forms/form-validation'

import useServiceFeeHandle from './useServiceFeeHandle'

export default {
  name: 'CreateProfileModal',
  directives: { 'b-tooltip': VBTooltip },
  components: {
    BModal,
    BTable,
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    vSelect,
    BButton,
    BFormInvalidFeedback,
    BTooltip,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    IAmInputMoney: () => import('@/components/IAmInputMoney.vue'),
    ValidationProvider,
    ValidationObserver,
    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),
  },
  props: {
    employeeId: {
      type: Number,
      default: null,
    },
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      const dropdownMaxWidth = width || '170px'
      dropdownList.style.border = '1px solid #b8c2cc'
      dropdownList.style.maxWidth = dropdownMaxWidth
      dropdownList.style.zIndex = '10000'
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [],
      })
      return () => popper.destroy()
    },
  },
  setup(props, { emit }) {
    const {
      tableColumns, createEmployeeProfile, loading, createServiceFeeMany,
    } = useServiceFeeHandle()

    const { refFormObserver, getValidationState } = formValidation()

    const tableColumnsForCreate = tableColumns.value.filter(item => !['action', 'updatedAt'].includes(item.key))

    const employeeProfileToAdd = ref()

    const serviceFeeConfigs = ref()

    const { isProduction, isHideConfigSourceTH } = env

    const distributorsList = computed(() => store.getters['globalConfig/getDistributors'])
    const sourceForInitServiceFeeConfigs = {
      INLAND: isProduction ? ['VN1A', 'VJ', 'QH', 'VU'] : ['VN1A', 'VJ', 'QH', 'VU', 'TRAIN'],
      INTERNATIONAL: ['VN1A', 'VJ', 'QH', 'VU', '1G'],
    }

    function showHandle() {
      employeeProfileToAdd.value = {
        name: '',
        isDefault: false,
      }
      const sourceList = {
        INLAND: unionWith(sourceForInitServiceFeeConfigs.INLAND, distributorsList.value.inland, (a, b) => a === b),
        INTERNATIONAL: unionWith(sourceForInitServiceFeeConfigs.INTERNATIONAL, distributorsList.value.international, (a, b) => a === b),
      }

      const blankServiceFeeConfigs = []
      serviceFeeConfigs.value = Object.keys(sourceList).forEach(ticketTypeItem => {
        sourceList[ticketTypeItem].forEach(sourceItem => {
          if (isHideConfigSourceTH && (sourceItem === 'TH')) return
          blankServiceFeeConfigs.push({
            airline: sourceItem,
            feeType: 'FLIGHT_ITINERARY',
            ticketType: ticketTypeItem,
            childAmount: 0,
            adultAmount: 0,
            infantAmount: 0,
            employeeProfileId: undefined,
          })
        })
      })
      serviceFeeConfigs.value = cloneDeep(blankServiceFeeConfigs)
    }
    const loadingCreate = ref(false)
    async function createEmployeeProfileHandle() {
      const isValid = await refFormObserver.value.validate()
      if (isValid) {
        loadingCreate.value = true
        try {
          const res = await createEmployeeProfile(props.employeeId, employeeProfileToAdd.value)
          const employeeProfileId = res.id
          if (employeeProfileId) {
            const payload = {
              items: serviceFeeConfigs.value.map(item => ({
                ...item,
                childAmount: item.childAmount || 0,
                adultAmount: item.adultAmount || 0,
                infantAmount: item.infantAmount || 0,
                employeeProfileId,
              })),
            }
            await createServiceFeeMany(props.employeeId, payload)
          }
          emit('getFeeConfigsData')
          this.$bvModal.hide('modal-create-profile')
        } catch (error) {
          console.error(error)
        } finally {
          loadingCreate.value = false
        }
      }
    }

    function handleInputAdultAmount(val, item) {
      item.childAmount = val
    }

    const handleApplyAll = item => {
      const valueToApply = item.adultAmount
      serviceFeeConfigs.value.forEach(fee => {
        if (fee.ticketType === item.ticketType) {
          fee.adultAmount = valueToApply
          fee.childAmount = valueToApply
          // fee.infantAmount = valueToApply
        }
      })
    }

    return {
      tableColumnsForCreate,
      showHandle,
      employeeProfileToAdd,
      ticketTypeOptions,
      serviceFeeConfigs,
      createEmployeeProfileHandle,
      loading,
      loadingCreate,
      feeTypeOptions,
      handleInputAdultAmount,
      handleApplyAll,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
input.vs__search {
  margin-top: 0;
}
</style>
